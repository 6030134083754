<script setup lang="ts">
</script>

<template>
    <div id="defaultLayout" class="grid grid-flow-row">
        <main class=" py-16 px-6 md:px-20">
            <slot />
        </main>
    </div>
</template>
